import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container, Image } from "react-bootstrap"

import gifImage from "../images/gif.svg"
import untangledImage from "../images/untangle.png"

const AboutUs = () => (
  <Layout>
    <SEO title="About Us" />
    <Container>
      <h1 style={{ marginTop: "2rem" }}>About Us</h1>
      <div style={{ margin: "2rem 0" }}></div>
      <p>
        <i>
          <strong style={{ color: "blue" }}>Async Network Solutions</strong>
        </i>{" "}
        was formed in 2002, designed specifically for small to medium businesses
        with limited IT resources and budgets. We also work towards optimizing
        networks while safeguarding data and devices.
      </p>
      <p>
        <strong>Anthony Hines</strong>, Founder/CEO offers over two decades of
        IT experience, specifically in network design and computer systems
        integration. He’s gained most of his knowledge working as an
        Infrastructure Engineer with a Fortune 100 Corporation.
      </p>
      <p>
        As a small business owner himself, he understands the needs and budgets
        of small to medium businesses and the need to have a safe and secure
        network.
      </p>
      <h4
        style={{
          textTransform: "uppercase",
          fontWeight: 700,
        }}
      >
        Services
      </h4>
      <p>
        Email Solutions, Network security software and appliances for small and
        medium business (SMB) education and nonprofit organizations. We provide
        connectivity options that work together seamlessly to ensure protection,
        monitoring, and control across the entire digital attack surface.
      </p>

      <h4
        style={{
          textTransform: "uppercase",
          fontWeight: 700,
        }}
      >
        Clients Served
      </h4>
      <p>K-12, Higher Education, Nonprofits and other small businesses.</p>
      <h4
        style={{
          textTransform: "uppercase",
          fontWeight: 700,
        }}
      >
        Testimonials
      </h4>
      <div>
        <blockquote>
          "I have worked with Tony for almost 20 years. He is extremely
          knowledgeable in all aspects of network security and server
          management.  He is very easy to work with and enjoys teaching you how
          to fix yourself where applicable. He is detail oriented, forward
          thinking, and committed to the task. Tony is the best guru you can
          have on speed dial!"
        </blockquote>
        <p style={{ marginBottom: 0 }}>Ms. Lonie Patterson</p>
        <p style={{ marginBottom: 0 }}>I.T. Coordinator</p>
        <p>Central Florida Prep School</p>

        <blockquote>
          "Tony has been a great asset in managing our network keeping us
          current with patch management and advising of threats or issues that
          were in our network before it became an issue. You will have a great
          partner Tony and his team supporting you."
        </blockquote>
        <p style={{ marginBottom: 0 }}>Mr. Bernie Durgin</p>
        <p>Business Manager</p>
      </div>
      <h4
        style={{
          textTransform: "uppercase",
          fontWeight: 700,
          marginTop: "2rem",
        }}
      >
        Home Office
      </h4>
      <p>Richmond, TX</p>
      <h4
        style={{
          textTransform: "uppercase",
          fontWeight: 700,
        }}
      >
        Our Partners
      </h4>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Image
          onClick={() => window.open("https://www.gfi.com/")}
          src={gifImage}
          rounded
          style={{ height: 50, cursor: "pointer", marginRight: "2rem" }}
        ></Image>
        <Image
          onClick={() => window.open("https://www.untangle.com/")}
          src={untangledImage}
          rounded
          style={{ maxHeight: 75, cursor: "pointer" }}
        ></Image>
      </div>
    </Container>
  </Layout>
)

export default AboutUs
